import { useForm } from "react-hook-form";
import { useState } from "react";
import useProfileListUpdateMutation from "../../../../../hooks/profile/useProfileListUpdateMutation";
import { ProfileListType } from "../../../../../types/profile/profileListTypes";
import { EnlargeBox } from "../../../../common/modal/EnlargeBox";
import PDFViewerModal from "../../../../common/pdfViewer/PDFViewer";
function ProfileDocumentCheckContainer({
  clickClose,
  profileData,
  modalName,
}: {
  clickClose: () => void;
  profileData?: ProfileListType;
  modalName: string;
}) {
  const { handleSubmit } = useForm();
  const mutation = useProfileListUpdateMutation(clickClose);
  const getDefaultFlag = () => {
    const flagMapping: Record<string, string | undefined> = {
      classTypeFace: profileData?.tutoringCertFlag,
      classTypeOnline: profileData?.mailOrderSalesCertFlag,
      education: profileData?.diplomaCertFlag,
    };

    return flagMapping[modalName] || "N";
  };
  const [certFlag, setCertFlag] = useState(getDefaultFlag());
  const [openImgBox, setOpenImgBox] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);

  const onSubmit = async () => {
    const formDataToSend = new FormData();

    if (modalName === "classTypeFace") {
      formDataToSend.append("tutoringCertFlag", certFlag);
      mutation.mutate({
        params: formDataToSend,
        endPoint: "tutoring-cert-flag",
      });
    } else if (modalName === "classTypeOnline") {
      formDataToSend.append("mailOrderSalesCertFlag", certFlag);
      mutation.mutate({
        params: formDataToSend,
        endPoint: "mail-order-sales-cert-flag",
      });
    } else if (modalName === "education") {
      formDataToSend.append("diplomaCertFlag", certFlag);
      mutation.mutate({
        params: formDataToSend,
        endPoint: "diploma-cert-flag",
      });
    }
  };

  const getModalData = (modalName: string) => {
    const modalData: Record<
      string,
      { title: string; img: string | undefined; doc: string | undefined }
    > = {
      classTypeFace: {
        title: "대면과외 필요서류",
        img: profileData?.certFaceToFaceImg,
        doc: profileData?.certFaceToFaceDoc,
      },
      classTypeOnline: {
        title: "화상과외 필요서류",
        img: profileData?.certOnlineImg,
        doc: profileData?.certOnlineDoc,
      },
      education: {
        title: "재학/졸업 증명서",
        img: profileData?.diplomaImg,
        doc: profileData?.diplomaDoc,
      },
    };

    return (
      modalData[modalName] || {
        title: "",
        img: undefined,
        doc: undefined,
      }
    );
  };

  const { title, img, doc } = getModalData(modalName);

  const handleCertFlag = () => {
    if (certFlag === "Y") {
      setCertFlag("N");
    } else {
      setCertFlag("Y");
    }
  };

  const originDocFileName = () => {
    if (modalName === "education") {
      return profileData?.diplomaDocOrg;
    }
    if (modalName === "classTypeFace") {
      return profileData?.certFaceToFaceDocOrg;
    }
    if (modalName === "classTypeOnline") {
      return profileData?.certOnlineDocOrg;
    }
    return "";
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="modal-dialog__header">
        <span className="modal-dialog__title">{title}</span>
        <button
          onClick={clickClose}
          type="button"
          className="modal-dialog__close"
        >
          <i className="icon i-close" />
        </button>
      </div>

      {img || doc ? (
        <>
          <div className="modal-dialog__body">
            <div className="msg-ly">
              <div className="msg-ly__text">
                <ul className="msg-ly__ul">
                  <li>선생님이 등록한 서류를 확인해주세요.</li>
                  <li>
                    서류에 문제가 없을 시 인증 배지 노출 설정을 해주시면 프로필
                    편집본에 인증배지가 노출됩니다.
                  </li>
                  <li>
                    선생님이 등록한 서류를 삭제하거나 수정 시 인증 배지는
                    자동으로 미노출됩니다. (필요시 노출 재설정 필요)
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-box flex-box--justify-between flex-box--items-center my5">
              <span className="typo-small strong">프로필에 인증 배지 노출</span>
              <div className="switcher">
                <span className="switcher__title" />
                <label className="switcher__label">
                  <input
                    type="checkbox"
                    className="switcher__input"
                    checked={certFlag === "Y"}
                    onChange={handleCertFlag}
                  />
                  <b />
                </label>
              </div>
            </div>
            {img && (
              <div
                className="certificate-preview"
                role={"presentation"}
                onClick={() => {
                  setOpenImgBox(true);
                }}
              >
                <div className="certificate-preview__img">
                  <img alt={"certificate-preview__img"} src={img} />
                </div>
              </div>
            )}
            {doc && (
              <div className="field-group">
                <div className="field-group__cont field-group__cont--no-style">
                  <div className="field-group__check-characters text-left">
                    <span className="color-black">문서</span>
                  </div>
                  <div className="file-download">
                    <div className="file-download__preview">
                      <i className="icon i-download" />
                      <div
                        role="presentation"
                        onClick={() => setOpenPdfViewer(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="name">
                          {originDocFileName()
                            ?.split(".")
                            .slice(0, -1)
                            .join(".")}
                        </span>
                        <span className="ext">
                          .{originDocFileName()?.split(".").pop()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="modal-dialog__footer modal-dialog__footer--sticky">
            <button
              type="button"
              className="btn btn--full btn--gray"
              onClick={clickClose}
            >
              취소
            </button>
            <button type="submit" className="btn btn--full btn--primary">
              저장하기
            </button>
          </div>
        </>
      ) : (
        <div className="modal-dialog__body">
          <div className="page-empty">등록된 서류가 없습니다.</div>
        </div>
      )}

      <PDFViewerModal
        open={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        pdfUrl={doc}
      />

      <EnlargeBox
        type={"img"}
        open={openImgBox}
        close={() => setOpenImgBox(false)}
        value={img}
      />
    </form>
  );
}

export default ProfileDocumentCheckContainer;
