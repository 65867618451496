import { useState, useEffect } from "react";
import { useTermsListQuery } from "../../../hooks/myInfo/terms/useTermsListQuery";
import { decodeContent } from "../../../util/util";
import SelectDropDown from "../../common/select/SelectDropDown";

function TermsOfServiceContainer({
  clickClose,
  noModal,
}: {
  clickClose?: () => void;
  noModal?: boolean;
}) {
  const { data: termsList } = useTermsListQuery({
    termsType: "TERMS_OF_SERVICE",
    displayFlag: "Y",
  });

  // termsList.items를 최신순으로 정렬
  const sortedItems = (termsList?.items || []).sort(
    (
      a: { effectiveDate: string | number | Date },
      b: { effectiveDate: string | number | Date },
    ) =>
      new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime(),
  );

  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState<string>(
    sortedItems[0]?.effectiveDate || "선택",
  );

  // effectiveDate 옵션 생성
  const effectiveDateOptions = sortedItems.reduce(
    (acc: { [x: string]: any }, item: { effectiveDate: string | number }) => {
      acc[item.effectiveDate] = item.effectiveDate; // key와 value를 동일하게 설정
      return acc;
    },
    {} as { [key: string]: string },
  );

  // 선택된 effectiveDate에 해당하는 항목 찾기
  const selectedTerm =
    sortedItems.find(
      (item: { effectiveDate: string }) =>
        item.effectiveDate === selectedEffectiveDate,
    ) || sortedItems[0]; // 기본값으로 첫 번째 항목 (최신) 사용

  // 데이터가 로드된 후 디폴트로 최신 날짜 설정
  useEffect(() => {
    if (sortedItems[0]?.effectiveDate) {
      setSelectedEffectiveDate(sortedItems[0].effectiveDate);
    }
  }, [sortedItems]);

  return (
    <>
      {!noModal && (
        <div className="modal-dialog__header">
          <span className="modal-dialog__title">과외노트 서비스 이용약관</span>
          <button
            type="button"
            className="modal-dialog__close"
            onClick={clickClose}
          >
            <i className="icon i-close" />
          </button>
        </div>
      )}
      <div className="modal-dialog__body">
        <SelectDropDown
          isFull={false}
          customMaxWidth={"10rem"}
          initialLabel="선택"
          options={effectiveDateOptions}
          value={selectedEffectiveDate}
          onChange={(value) => setSelectedEffectiveDate(value as string)}
        />
        <div
          style={{
            height: "100%",
            overflowY: "auto",
          }}
          className="termsBox"
          dangerouslySetInnerHTML={{
            __html: decodeContent(selectedTerm?.content || ""),
          }}
        />
      </div>
    </>
  );
}

export default TermsOfServiceContainer;
