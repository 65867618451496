import { Controller, useForm, FormProvider } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import CommonInput from "../../../common/form/CommonInput";
import CheckboxGroup from "./CheckBoxGroup";
import { useSignUpMutation } from "../../../../hooks/auth/useSignUpMutation";
import { ImageAndDocumentUploadSection } from "../../profile/section/ImageAndDocumentUploadSection";
import { formDirtyStateAtom } from "../../../../store/common/formDirtyStateStore";

interface FilePreview {
  name: string;
  ext: string;
  file: File;
}

function SignUpForm() {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      name: "",
      bizPhone: "",
      agreements: {
        age: false,
        terms: false,
        privacy: false,
        pushAgree: false,
        pushAgreeEvent: false,
      },
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods;
  const formDataToSend = new FormData();
  const signUpMutation = useSignUpMutation();
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>("");
  const [documentFile, setDocumentFile] = useState<FilePreview | null>(null);
  const setIsFormDirty = useSetRecoilState(formDirtyStateAtom);

  const onSubmit = async (formData: any) => {
    console.log("Form Data:", formData);

    formDataToSend.append("loginId", formData.email);
    formDataToSend.append("memberType", "BUSINESS");
    formDataToSend.append("loginPassword", formData.password);
    formDataToSend.append("passwordConfirm", formData.password);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("bizPhone", formData.bizPhone.replace(/-/g, ""));
    // TODO 추후 수정 필요 사업자는 휴대폰 번호를 직접 입력하지 않음
    formDataToSend.append("mobile", "0000000000");
    formDataToSend.append(
      "pushAgree",
      `${formData?.agreements.pushAgree === true ? "AGREE" : "DISAGREE"}`,
    );
    formDataToSend.append(
      "pushAgreeEvent",
      `${formData?.agreements.pushAgreeEvent === true ? "AGREE" : "DISAGREE"}`,
    );

    if (imageSrc) {
      const imageBlob = await fetch(imageSrc.toString()).then((res) =>
        res.blob(),
      );
      formDataToSend.append(
        "bizRegistrationImgFile",
        imageBlob,
        "bizRegistrationImgFile.png",
      );
    }

    if (documentFile) {
      formDataToSend.append(
        "bizRegistrationDocFile",
        documentFile.file,
        "bizRegistrationDocFile.pdf",
      );
    }

    signUpMutation.mutate(formDataToSend);
  };

  const checkboxOptions = [
    { label: "[필수] 만 14세 이상입니다", value: "age", required: true },
    {
      label: "[필수] 이용약관 동의",
      value: "terms",
      required: true,
      showModalType: "terms",
    },
    {
      label: "[필수] 개인정보 처리방침 동의",
      value: "privacy",
      required: true,
      showModalType: "privacy",
    },
    {
      label: "[필수] 알림 수신 동의",
      value: "pushAgree",
      required: true,
    },
    {
      label: "[선택] 마케팅 정보 수신 동의",
      value: "pushAgreeEvent",
      required: false,
    },
  ];

  const onImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) {
      return;
    }

    const file: File = files[0];
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise<void>((resolve) => {
      reader.onload = () => {
        setImageSrc(reader.result);
        if (reader.result) {
          setIsFormDirty(true);
        }
        resolve();
      };
    });
  };

  const onDocumentUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) {
      return;
    }

    const file: File = files[0];
    const [name, ext] = file.name.split(".");

    const newFile = {
      name,
      ext: `.${ext}`,
      file,
    };
    setDocumentFile(newFile);
    if (newFile) {
      setIsFormDirty(true);
    }
  };

  useEffect(() => {
    const isImageChanged = Boolean(
      (imageSrc && imageSrc !== "") || (!imageSrc && ""),
    );

    const isDocChanged = Boolean(
      (documentFile && JSON.stringify(documentFile) !== JSON.stringify(null)) ||
        (!documentFile && null),
    );

    const isFormChanged = isDirty || isImageChanged || isDocChanged;
    setIsFormDirty(isFormChanged);

    return () => setIsFormDirty(false);
  }, [isDirty, imageSrc, documentFile, setIsFormDirty]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <main className="content pb0">
            <div className="section pb16">
              <div className="field-group">
                <label htmlFor="" className="field-group__label">
                  이메일
                </label>
                <Controller
                  name="email"
                  control={methods.control}
                  rules={{
                    required: "이메일은 필수 항목입니다.",
                    pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "올바른 이메일 형식을 입력해주세요.",
                    },
                  }}
                  render={({ field }) => (
                    <CommonInput
                      placeholder="이메일 주소를 입력해주세요"
                      field={field}
                      error={errors.email?.message}
                    />
                  )}
                />
              </div>

              <div className="field-group">
                <label htmlFor="" className="field-group__label">
                  비밀번호
                </label>
                <Controller
                  name="password"
                  control={methods.control}
                  rules={{
                    required: "비밀번호는 필수 항목입니다.",
                    pattern: {
                      value:
                        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,20}$/,
                      message:
                        "영문/숫자/특수문자 3가지 조합(8~20자)으로 입력해주세요.",
                    },
                  }}
                  render={({ field }) => (
                    <CommonInput
                      placeholder="비밀번호를 입력해주세요"
                      field={field}
                      type="password"
                      error={errors.password?.message}
                    />
                  )}
                />
              </div>

              <div className="field-group">
                <label htmlFor="" className="field-group__label">
                  사업자명
                </label>
                <Controller
                  name="name"
                  control={methods.control}
                  rules={{
                    required: "사업자명은 필수 항목입니다.",
                  }}
                  render={({ field }) => (
                    <CommonInput
                      placeholder="사업자명 입력해주세요"
                      field={field}
                      error={errors.name?.message}
                    />
                  )}
                />
              </div>

              <div className="field-group">
                <label htmlFor="" className="field-group__label">
                  사업자번호
                </label>
                <Controller
                  name="bizPhone"
                  control={methods.control}
                  rules={{
                    required: "사업자번호는 필수 항목입니다.",
                    pattern: {
                      value: /^\d{3}-\d{2}-\d{5}$/,
                      message: "사업자번호 10자리를 입력해주세요",
                    },
                  }}
                  render={({ field }) => (
                    <CommonInput
                      placeholder="숫자만 입력"
                      field={field}
                      type="tel"
                      error={errors.bizPhone?.message}
                      maskBusinessNumber
                    />
                  )}
                />
              </div>

              <ImageAndDocumentUploadSection
                title="사업자등록증 등록 (선택)"
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
                documentFile={documentFile}
                setDocumentFile={setDocumentFile}
                onImageUpload={onImageUpload}
                onDocumentUpload={onDocumentUpload}
              />

              <CheckboxGroup name="agreements" options={checkboxOptions} />
            </div>
            <div className="content-footer">
              <button
                id="btn-submit"
                className={`btn btn--full btn--primary ${!isValid ? "is-disabled" : ""}`}
                type="submit"
              >
                다음
              </button>
            </div>
          </main>
        </form>
      </FormProvider>
    </>
  );
}

export default SignUpForm;
