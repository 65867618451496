import { atom } from "recoil";
import { ConfigListType } from "../../types/config/configTypes";

export const configAtom = atom<ConfigListType>({
  key: "configList",
  default: {
    deviceType: {
      A: "",
      G: "",
    },
    boardKind: {
      NOTICE: "",
      FAQ: "",
      FREE: "",
    },
    msgKindType: {
      REQ_COURSE: "",
      QNA: "",
      REQ_PARTNER: "",
    },
    memberStatus: {
      WITHDRAW: "",
      ACTIVE: "",
      INACTIVE: "",
    },
    YNFlag: {
      Y: "",
      N: "",
    },
    mappingStatus: {
      ACCEPT: "",
      REJECT: "",
      REQUEST: "",
    },
    schoolStatus: {
      GRADUATE: "",
      ABSENCE: "",
      ATTEND: "",
    },
    agreeStatus: {
      DISAGREE: "",
      AGREE: "",
    },
    courseShareStatus: {
      ACCEPT: "",
      REJECT: "",
      EXPIRE: "",
      REQUEST: "",
    },
    reqStatus: {
      COMPLETE: "",
      REJECT: "",
      EXPIRE: "",
      CANCEL: "",
      WAIT: "",
    },
    dayOfWeek: {
      "1": "",
      "2": "",
      "3": "",
      "4": "",
      "5": "",
      "6": "",
      "7": "",
    },
    demoClassType: {
      PAID: "",
      "DENY ": "",
      FREE: "",
    },
    courseStatus: {
      COMPLETE: "",
      EXPECT: "",
      ONGOING: "",
    },
    admissionProcessType: {
      COMMUNITY: "",
      "ETC ": "",
      GENERAL: "",
      SPECIAL: "",
      OPPORTUNITY: "",
      REGIONAL: "",
    },
    admissionSelectionType: {
      CURRICULUM: "",
      REGULAR: "",
      COMPREHENSIVE: "",
      APTITUDE: "",
      DISCOURSE: "",
      "ETC ": "",
    },
    memberType: {
      TUTOR: "",
      PARENTS: "",
      BUSINESS: "",
      ADMIN: "",
    },
    classType: {
      ALL: "",
      FACE: "",
      ONLINE: "",
    },
    classIndex: {},
    classTime: {},
    scoreAttitudeTest: {},
    scoreCompletion: {},
    preferGradeType: {},
    cashReceiptFlag: {},
    ordinalNum: {},
    colorNumber: {},
  },
});
