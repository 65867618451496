import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import useBoardInfoQuery from "../../../../../hooks/myInfo/board/useBoardInfoQuery";
function FreqQuestionInfo() {
  const location = useLocation();
  const { data: boardInfo } = useBoardInfoQuery(location.state);

  return (
    <main className="content">
      <div className="section">
        <div className="board-view">
          <div className="board-view__head">
            <div className="board-view__title">{boardInfo?.boardTitle}</div>
          </div>
          <div className="board-view__body">
            {/* <div> */}
            {/*   <img style="width:100%;" src="https://m.kyochon.com/resources/image/contents/event/customEvent/img_event_custom_10.jpg" alt=""> */}
            {/* </div> */}
            {/* <br /> */}
            {boardInfo?.boardContent && (
              <Box
                sx={{}}
                dangerouslySetInnerHTML={{
                  __html: boardInfo?.boardContent,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default FreqQuestionInfo;
