import CryptoJS from "crypto-js";

const secretKey: string = process.env.REACT_APP_SECRET_KEY as string;

// 암호화 함수
export const encrypt = (data: string): string => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

// 복호화 함수
export const decrypt = (encryptedData: string): string => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// 암호화된 링크 생성 함수
export const generateEncryptedLink = (
  ID1: string,
  expireDate: number,
  type: string,
  ID2?: string,
): string => {
  const data: string = ID2
    ? `${ID1}|${ID2}|${expireDate}`
    : `${ID1}|${expireDate}`;
  return `/${type}/pre/ext/${encodeURIComponent(encrypt(data))}`;
};
