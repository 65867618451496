import { useQuery } from "react-query";
import axios from "axios";
import AuthApi from "../../../services/auth/AuthApi";

const fetchTermsList = async (params: {
  termsType: string;
  displayFlag: string;
}): Promise<any> => {
  const baseUrl = AuthApi.getBaseUrl();
  try {
    return await axios.post(`${baseUrl}/api/v1/business/terms/list`, {
      ...params,
    });
  } catch (error) {
    console.error("password-update failed:", error);
    throw error;
  }
};

export const useTermsListQuery = (params: {
  termsType: string;
  displayFlag: string;
}) => {
  return useQuery(["termsList", params], () => fetchTermsList(params), {
    onSuccess: (data) => {
      console.log("Success", data);
    },
    onError: (error) => {
      console.error("failed:", error);
    },
  });
};
