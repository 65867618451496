import { useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface PDFViewerModalProps {
  open: boolean;
  onClose: () => void;
  pdfUrl?: string;
}

const PDFViewerModal = ({ open, onClose, pdfUrl }: PDFViewerModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadPDF = async () => {
      if (!open || !pdfUrl) return;

      try {
        const response = await fetch(pdfUrl);
        const pdfData = await response.arrayBuffer();
        const loadingTask = pdfjsLib.getDocument({ data: pdfData });
        const pdf = await loadingTask.promise;

        const pagePromises = Array.from(
          { length: pdf.numPages },
          async (_, i) => {
            const pageNum = i + 1;
            const page = await pdf.getPage(pageNum);
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            const containerWidth = containerRef.current?.clientWidth || 800;
            const originalViewport = page.getViewport({ scale: 1.0 });

            const baseScale = containerWidth / originalViewport.width;
            const deviceScale = window.devicePixelRatio || 1;
            const scale = baseScale * 1;

            const viewport = page.getViewport({ scale });

            canvas.width = viewport.width * deviceScale;
            canvas.height = viewport.height * deviceScale;
            canvas.style.width = `${viewport.width}px`;
            canvas.style.height = `${viewport.height}px`;

            context?.scale(deviceScale, deviceScale);

            await page.render({
              canvasContext: context!,
              viewport,
            }).promise;

            // 구분선을 포함한 wrapper div 생성
            const wrapper = document.createElement("div");
            wrapper.style.borderBottom = "5px solid #e0e0e0";
            wrapper.appendChild(canvas);

            return wrapper;
          },
        );

        if (containerRef.current) {
          containerRef.current.innerHTML = "";
          const canvases = await Promise.all(pagePromises);
          canvases.forEach((canvas) =>
            containerRef.current?.appendChild(canvas),
          );
        }
      } catch (error) {
        console.error("PDF 로딩 실패:", error);
      }
    };

    loadPDF();

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        containerRef.current.innerHTML = "";
      }
    };
  }, [open, pdfUrl]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="pdf-viewer-modal"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          outline: "none",
          overflow: "auto",
        }}
      >
        <div style={{ position: "absolute", right: 10, top: 10 }}>
          <button
            onClick={onClose}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <i className="icon i-close" />
          </button>
        </div>
        <div
          ref={containerRef}
          style={{
            marginTop: "10px",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "auto",
          }}
        />
      </Box>
    </Modal>
  );
};

export default PDFViewerModal;
