import { useQuery } from "react-query";
import axios from "axios";
import AuthApi from "../../../services/auth/AuthApi";

const fetchProfilePreview = async (params: {
  id: string;
  url: string;
}): Promise<any> => {
  const baseUrl = AuthApi.getBaseUrl();
  try {
    return await axios.post(`${baseUrl}/api/v1/preview/profile`, { ...params });
  } catch (error) {
    console.error("password-update failed:", error);
    throw error;
  }
};

export const useProfilePreviewExternalQuery = (params: {
  id: string;
  url: string;
}) => {
  return useQuery(
    ["profilePreview", params],
    () => fetchProfilePreview(params),
    {
      onSuccess: (data) => {
        console.log("Success", data);
      },
      onError: (error) => {
        console.error("failed:", error);
      },
    },
  );
};
