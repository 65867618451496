import moment from "moment";
import { useRecoilValue } from "recoil";
import { StudyCalendar } from "../../../types/studyCalendar/studyCalendarTypes";
import { translateValue } from "../../../util/util";
import { configAtom } from "../../../store/common/configStore";

function StudyCalendarClassDayCard({
  data,
  type,
}: {
  data: StudyCalendar;
  type?: string;
}) {
  const config = useRecoilValue(configAtom);
  return (
    <>
      <li
        className={`schedule-main__item ${type !== "home" && "schedule-main__item--type2"}`}
      >
        <span
          className="schedule-main__line"
          style={{
            backgroundColor: translateValue(
              String(data?.colorNumber),
              config.colorNumber,
            ),
          }}
        />
        <div className="schedule-main__title">
          {data?.studentNames.map((student) => student.studentName).join(", ")}
          <em className="dot" />
          {data?.className}
          <em className="dot" /> {data?.location}
        </div>
        <div className="schedule-main__time">
          {" "}
          {moment(data?.classStartTime).format("a hh:mm")} ~
          {moment(data?.classEndTime).format("a hh:mm")}
        </div>
      </li>
    </>
  );
}

export default StudyCalendarClassDayCard;
