import { useQuery, UseQueryResult } from "react-query";
import BoardApi from "../../../services/myInfo/BoardApi";
import { BoardCategoryListType } from "../../../types/myInfo/boardTypes";

const useBoardCategoryListQuery = ({
  endPoint,
}: {
  endPoint?: string;
}): UseQueryResult<BoardCategoryListType, Error> => {
  return useQuery<BoardCategoryListType, Error>({
    queryKey: ["boardCategoryList", endPoint],
    queryFn: () => BoardApi.fetchBoardCategoryList(endPoint || ""),
    onSuccess: (data: BoardCategoryListType) => {
      console.log("Query successful:", data);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useBoardCategoryListQuery;
