import { useLocation, useNavigate } from "react-router-dom";
import {
  useState,
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
} from "react";
import { useRecoilValue } from "recoil";
import useFreqQuestionListQuery from "../../../../../hooks/myInfo/board/useFreqQuestionListQuery";
import { userAtom } from "../../../../../store/common/userStore";
import useBoardCategoryListQuery from "../../../../../hooks/myInfo/board/useBoardCategoryListQuery";

function FreqQuestionContainer() {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState<string>(
    location?.state?.category ?? "",
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const user = useRecoilValue(userAtom);

  const { data: freqQuestionList } = useFreqQuestionListQuery({
    memberType: user.member.memberType,
    searchType: "title",
    searchText,
    faqCategory: selectedCategory,
    viewFlag: "Y",
  });
  const { data: categoryList } = useBoardCategoryListQuery({ endPoint: "" });
  const navigate = useNavigate();
  const [showTabs, setShowTabs] = useState<boolean>(true);

  const handleSearch = () => {
    if (searchInput !== "") {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
    setSearchText(searchInput);
  };

  const handleKeyDown = (e: ReactKeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleClear = () => {
    setSearchInput("");
    setSearchText("");
    setShowTabs(true);
  };

  return (
    <>
      <main className="content">
        <div className="field-group mt0 pos-sticky">
          <label
            htmlFor="searchInput"
            className="field-group__label field-group__label--blind"
          >
            자주 묻는 질문 검색
          </label>
          <div className="field-group__cont">
            <div role="presentation" onClick={handleSearch}>
              <i className="icon i-search" />
            </div>
            <input
              type="text"
              name="searchInput"
              id="searchInput"
              className="field-group__input"
              value={searchInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="궁금한 점을 검색해보세요"
            />
            {searchInput && (
              <span
                className="field-group__clear"
                role="presentation"
                onClick={handleClear}
              />
            )}
          </div>
        </div>
        {showTabs ? (
          <div className="tabs-bar pt2 pb4">
            <ul className="tabs-bar__group">
              <div
                className={`tabs-bar__item ${selectedCategory === "" ? "tabs-bar__item--active" : ""}`}
                role="presentation"
                onClick={() => setSelectedCategory("")}
              >
                <span>전체</span>
              </div>
              {categoryList?.items
                .filter((item) => item.memberType === user.member.memberType)
                .map((item) => (
                  <div
                    key={item.id}
                    className={`tabs-bar__item ${selectedCategory === String(item.id) ? "tabs-bar__item--active" : ""}`}
                    role="presentation"
                    onClick={() => setSelectedCategory(String(item.id))}
                  >
                    <span>{item.category}</span>
                  </div>
                ))}
            </ul>
          </div>
        ) : (
          <div className="sorting pt2 pb4">
            <span className="sorting__title">
              검색결과 <b>{freqQuestionList?.items.length || 0}</b> 건
            </span>
          </div>
        )}
        <div className="divider" />
        <div className="section">
          <div className="board-list">
            <ul className="board-list__group">
              {freqQuestionList?.items.map((item, key) => (
                <li
                  key={key}
                  className="board-list__item"
                  role={"presentation"}
                  onClick={() => {
                    navigate("/my-info/freq-question/info", {
                      state: item.id,
                    });
                  }}
                >
                  <div className="board-list__link py5">
                    <div className="board-list__title">{item.boardTitle}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
    </>
  );
}

export default FreqQuestionContainer;
