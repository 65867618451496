import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import koLocale from "@fullcalendar/core/locales/ko";
import { useRecoilValue } from "recoil";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";
import { Divider } from "@mui/material";
import StudyCalendarToolbar from "./StudyCalendarToolbar";
import StudyCalendarDayList from "./StudyCalendarClassDayList";
import useStudyCalendarListQuery from "../../../hooks/studyCalendar/useStudyCalendarListQuery";
import { userAtom } from "../../../store/common/userStore";
import { ColorDataType } from "../../../types/studyCalendar/studyCalendarTypes";
import { selectedPartnerInfoAtom } from "../../../store/profile/selectedPartnerInfoStore";
import { translateValue } from "../../../util/util";
import { configAtom } from "../../../store/common/configStore";

const StudyCalendarContainer: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format("YYYY-MM-DD"),
  );
  const user = useRecoilValue(userAtom);
  const [currentMonth, setCurrentMonth] = useState(moment().format("YYYY-MM"));
  const calendarRef = useRef<FullCalendar>(null);
  const [colorData, setColorData] = useState<ColorDataType[]>([]);
  const selectedPartnerInfo = useRecoilValue(selectedPartnerInfoAtom);
  const config = useRecoilValue(configAtom);
  const queryParams = {
    month: currentMonth,
    memberCode: user?.member?.id,
    tutorCode: selectedPartnerInfo.tutorCode,
  };

  const { data, refetch } = useStudyCalendarListQuery({
    params: queryParams,
    endPoint: "month",
  });

  useEffect(() => {
    refetch();
  }, [currentMonth, refetch]);

  useEffect(() => {
    if (data) {
      type EventType = (typeof data.items)[0];
      const eventsByDate: { [key: string]: EventType[] } = data.items.reduce(
        (acc: { [key: string]: EventType[] }, event: EventType) => {
          const date = moment(event.classDate).format("YYYY-MM-DD");
          if (!acc[date]) acc[date] = [];
          acc[date].push(event);
          return acc;
        },
        {},
      );

      // 날짜별로 이벤트들을 정렬 (classStartTime -> id 순)
      const processedEvents = Object.keys(eventsByDate).flatMap((date) => {
        const sortedEvents = eventsByDate[date].sort((a, b) => {
          const timeDiff = moment(a.classStartTime).diff(
            moment(b.classStartTime),
          );
          if (timeDiff === 0) {
            return a.id - b.id; // 시작 시간이 같은 경우 id로 정렬
          }
          return timeDiff;
        });
        return sortedEvents.map((event) => ({
          id: event.id.toString(),
          title: event.studentNames.map((s) => s.studentName).join(", "),
          start: event.classStartTime,
          end: event.classStartTime,
          color: translateValue(String(event?.colorNumber), config.colorNumber),
        }));
      });

      setColorData(processedEvents);
    }
  }, [config.colorNumber, data]);

  const handleDateClick = (info: { date: moment.MomentInput }) => {
    const clickedDate = moment(info.date).format("YYYY-MM-DD");
    setSelectedDate(clickedDate);
    setIsCollapsed(true);
  };

  const handleEventClick = (info: { event: { start: moment.MomentInput } }) => {
    const clickedDate = moment(info.event.start).format("YYYY-MM-DD");
    setSelectedDate(clickedDate);
    setIsCollapsed(true);
  };

  const dayCellClassNames = (date: { date: moment.MomentInput }) => {
    const formattedDate = moment(date.date).format("YYYY-MM-DD");
    return formattedDate === selectedDate ? ["selected-date-cell"] : [];
  };

  const renderDayCellContent = (arg: { date: moment.MomentInput }) => {
    const formattedDate = moment(arg.date).format("D");
    return <span>{formattedDate}</span>;
  };

  const renderEventContent = (eventInfo: { event: { title: string } }) => {
    return isCollapsed ? (
      <span>&nbsp;</span>
    ) : (
      <span>{eventInfo.event.title}</span>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StudyCalendarToolbar
        calendarRef={calendarRef}
        setSelectedDate={setSelectedDate}
        setCurrentMonth={setCurrentMonth}
        currentMonth={currentMonth}
      />
      <div>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={colorData}
          height={isCollapsed ? 400 : 730}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          dayCellClassNames={dayCellClassNames}
          eventDisplay={"block"}
          dayMaxEventRows={isCollapsed ? false : 5}
          locale={koLocale}
          dayCellContent={renderDayCellContent}
          eventContent={renderEventContent}
          eventClassNames={() => (isCollapsed ? "collapsed-event" : "")}
          headerToolbar={false} // 기본 툴바 숨기기
        />
      </div>

      {isCollapsed && (
        <>
          <Divider>
            <KeyboardDoubleArrowDownRoundedIcon
              style={{
                transform: "scaleX(2) translateY(3px)",
                color: "gray",
              }}
              onClick={() => {
                setIsCollapsed(false);
              }}
            />
          </Divider>

          <StudyCalendarDayList selectedDate={selectedDate} />
        </>
      )}
    </div>
  );
};

export default StudyCalendarContainer;
